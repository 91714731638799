import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import { FaInfoCircle } from 'react-icons/fa'
import { graphql, useStaticQuery } from 'gatsby'

import SEO from '~/components/SEO'
import Title from '~/components/Title'
import Layout from '~/components/Layout'

import { Container, Galeria } from '~/styles/pages/sobre'

const QUERY_IMAGES = graphql`
{
  imgFrente: file(relativePath: { eq: "foto-frente.jpeg" }) {
    childImageSharp {
      fixed(width: 920, quality: 100) {
        ...GatsbyImageSharpFixed
      }
    }
  },
  imgEscritorio: file(relativePath: { eq: "foto-escritorio.jpeg" }) {
    childImageSharp {
      fixed(width: 545, quality: 100) {
        ...GatsbyImageSharpFixed
      }
    }
  },
  imgRecepcao: file(relativePath: { eq: "foto-recepcao.jpeg" }) {
    childImageSharp {
      fixed(width: 545, quality: 100) {
        ...GatsbyImageSharpFixed
      }
    }
  },
}`

const AboutPage = () => {
  const year = new Date().getFullYear()
  const { imgFrente, imgRecepcao, imgEscritorio } = useStaticQuery(QUERY_IMAGES)

  return (
    <Layout>
      <SEO title="Quem Somos" description="Leia aqui um pouco sobre quem somos e a a histório do nosso escritório" />
      <Title content="Quem Somos" icon={<FaInfoCircle />} />
      <Container>
        <p>
          Contamos com uma equipe de diversos profissionais das Áreas do Direito e
          afins. Nossa equipe passa por constante aprimoramento e atualização técnica
          para fazer face à atuação nos diversos ramos do direito. Advogados com mais de {}
          {year - 1996} anos de atuação, com Titulação de Mestres e Especialistas, professores de diversas
          Instituições de Ensino Superior, em nível de Graduação e Pós-Graduação.
        </p>

        <Galeria>
          <span>
            <Img
              fixed={imgFrente.childImageSharp.fixed}
              alt="Foto da frente do escritório da CasaGrande Advogados"
              imgStyle={{ objectFit: 'cover' }}
            />
          </span>
          <span>
            <Img
              fixed={imgEscritorio.childImageSharp.fixed}
              alt="Foto da frente do escritório da CasaGrande Advogados"
              imgStyle={{ objectFit: 'cover' }}
            />
          </span>
          <span>
            <Img
              fixed={imgRecepcao.childImageSharp.fixed}
              alt="Foto da frente do escritório da CasaGrande Advogados"
              imgStyle={{ objectFit: 'cover' }}
            />
          </span>
        </Galeria>
      </Container>
    </Layout>
  )
}

export default AboutPage
