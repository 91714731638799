import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  align-self: center;
  flex-direction: column;
  row-gap: 40px;

  width: 100%;
  max-width: 1140px;

  padding-bottom: 64px;

  p {
    flex: 1;
    color: #333;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 2.25rem;
    text-indent: 50px;
    margin: 0;
  }
`

export const Galeria = styled.div`
  display: grid;
  grid-template-columns: 630px 1fr;
  grid-template-rows: 250px 250px;
  gap: 20px;

  width: 100%;

  span {
    width: 100%;
    background: blue;
    overflow: hidden;
    border-radius: 6px;

    &:first-child {
      grid-row: 1 / 3;
    }
  }
`
